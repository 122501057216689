<template>
 
  <div class="login_page">
    <v-header :params="{ title, leftIcon: true }"></v-header>
    <div class="content">
<!--      <van-field label="账号类型">-->
<!--        <template #input>-->
<!--          <van-radio-group v-model="userType" direction="horizontal">-->
<!--            <van-radio name="PHONE">手机登录</van-radio>-->
<!--            <van-radio name="EMAIL">邮箱登录</van-radio>-->
<!--            <van-radio name="SMS" style="margin-top: 10px;">短信验证码登录</van-radio>-->
<!--          </van-radio-group>-->
<!--        </template>-->
<!--      </van-field>-->
      <template v-if="userType === 'PHONE'">
        <van-field class="inputMessage" left-icon="phone-o" v-model.trim="username" type="tel" label="手机号" maxlength="11" oninput="value=value.replace(/[^\d]/g,'')" clearable placeholder="请输入手机号" />
        <van-field class="inputMessage" left-icon="shield-o" v-model.trim="userPwd" type="password" label="密码" clearable placeholder="请输入密码" />
      </template>
      <template v-else-if="userType === 'EMAIL'" style="border-radius: 10px;margin-bottom: 10px;">
        <van-field class="inputMessage" left-icon="envelop-o" v-model.trim="username" label="邮箱" clearable placeholder="请输入邮箱" />
        <van-field class="inputMessage" left-icon="shield-o" v-model.trim="userPwd" type="password" label="密码" clearable placeholder="请输入密码" />
      </template>
      <template v-if="userType === 'SMS'" style="border-radius: 10px;margin-bottom: 10px;">
        <van-field class="inputMessage" left-icon="phone-o" v-model.trim="username" type="tel" clearable label="手机号" maxlength="11" oninput="value=value.replace(/[^\d]/g,'')" placeholder="请输入手机号" />
        <van-field class="inputMessage" left-icon="shield-o" v-model.trim="smsVerifyCode" center clearable label="短信验证码" placeholder="请输入短信验证码">
          <template #button>
            <van-button size="small" type="primary" @click="sendCode">
              <span v-if="!sendDisabled">发送验证码</span>
              <span v-else class="smsVerifyCode">{{time+'秒后重新获取'}}</span>
            </van-button>
          </template>
        </van-field>
      </template>
      <van-field label="账号类型" left-icon="exchange" style="border-radius: 10px;margin-bottom: 10px;">
        <template #input>
          <van-radio-group v-model="userType" direction="horizontal">
            <van-radio name="PHONE">账号密码登录</van-radio>
            <br>
            <!-- <van-radio name="EMAIL" style="margin-top: 5px">邮箱登录</van-radio>
            <br> -->
            <van-radio name="SMS" style="margin-top: 10px;">短信一键登录</van-radio>
          </van-radio-group>

        </template>
      </van-field>
      <div style="margin: 16px;display: flex;justify-content: space-evenly;">
        <van-button round type="info" @click="newOnSubmit" style="width:160px; max-width: 180px;margin-left: 10px;">登录</van-button>
<!--        <br>-->
        <!-- <van-button round type="default" @click="goPage('register')" style="width:160px; max-width: 180px;margin-left: 20px;">注册</van-button> -->
      </div>
      <van-dialog v-model="verCodeShow"
                  @confirm="verCodeClick"
                  :show-confirm-button="true"
                  :show-cancel-button="true"
                  :confirm-button-text="'提交'">
        <div class="verCodeShow">
          <span style="display: inline-block;margin-top: 10px;padding: 10px;background-color: #7a7a7a;color: blue">{{this.verCode}}</span>
          <br>
          <button @click="createVerCode" style="font-size: 0.3rem;margin: 0.3rem 2px;border: none;">看不清，换一张</button>
        </div>
        <div class="verCodeInput">
          <van-cell-group inset>
            <van-field v-model="verCodeText"  placeholder="请输入验证码" />
          </van-cell-group>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import vHeader from "@/components/header.vue";
import validator from '@/assets/js/util/validator';
import isWeixin from '@/utils/utils.js'


export default {
  components: {
    vHeader,
  },
  data() {
    return {
      title: this.$route.meta.title,
      appId: 'webhaojingchouh5',
      userType: 'SMS',  //    PHONE, SMS
      username: '',  // 手机号码 或 邮箱
      smsVerifyCode: '',  // 验证码
      userPwd: '',
      sendDisabled: false,
      time: 60,
      token:'',
      art:0,
      verifyCode:0,
      verCodeShow: false,
      verCodeText: '',
      verCode: '',
    };
  },
  mounted:function(){
    //判断网页是什么打开
    let isWeiXin = isWeixin()
    if(isWeiXin){
      this.showHeader = false
    }
    if(this.showHeader==false){
      document.title = ''
    }
    let art = this.$route.query.art;
    this.art = art
  },
  methods: {
    createVerCode(){
      let alpNumberList = ['1','2','3','4','5','6','7','8','9','0','a','b','c','d','e','f'];
      let codeList = [];
      while (codeList.length < 4){
        let pushNum = alpNumberList[Math.floor(Math.random()*alpNumberList.length)];
        codeList.push(pushNum);
      }
      this.verCode = codeList.join('');
    },
    verCodeClick(){
      if (this.verCodeText === this.verCode){
        this.onSubmit();
        this.verCodeShow = false;
        this.verCodeText = '';
        this.verCode = '';
      }else {
        this.$toast("验证码错误");
      }
    },
    newOnSubmit(){
       if (this.verifyCode > 1){
         this.verCodeShow = true;
         this.createVerCode();
       }else {
         this.onSubmit();
       }
    },
    async onSubmit() {
      let data = {
        appId: 'webhaojingchouh5',
        deviceLoginType:'H5WEB',//   PC_WEB, H5WEB, MINIPRO, PHONE, PAD
        username: this.username,  // 手机号码 或 邮箱
      }

      if (this.userType === 'PHONE') {
        if (!validator.isMobile(this.username, "手机号码格式有误")) {
          return;
        }
      }
      
      // 密码
      if (this.userType === 'PHONE' || this.userType === 'EMAIL') {
        data.userPwd = this.userPwd
        if (!this.userPwd) {
          this.$toast('请输入密码')
          return;
        }
      }
      // 验证码
      if (this.userType === 'SMS') {
        data.smsVerifyCode = this.smsVerifyCode
      }
      const res = await this.$HTTP.postLogin(this, 'API_USERCENTER_USER_ANON_LOGIN_REGISTER', data, {}, true)
      this.token = res.access_token
      if (res.statusCode === 200) {
        this.$store.commit('common/updateState', [
          { name: ['loginInfo'], value: Object.assign(this.$store.state.common.loginInfo, res) }
        ])
        if (this.$route.params.return_url != null) {
          window.location.href = this.$route.params.return_url;
        }else {
          setTimeout(() => {
              this.$router.push('home')
            }, 1000)
        }
        this.$toast('登录成功');
      }else if (res.statusCode !== 200){
        this.verifyCode = this.verifyCode+1;
      }
    },
    //获取验证码
    async sendCode() {
      if (this.sendDisabled) {
        return;
      }
      if (!validator.isMobile(this.username, "手机号码格式有误")) {
        return
      }
      const res = await this.$HTTP.post(this, 'API_USERCENTER_LOGIN_SIGNUP_CODE', {
        phoneNumber: this.username,
        smsType: '2' // smsType 发短信类型 1注册 2登录
      }, {}, true)
      if (res.statusCode === 200) {
        // this.smsVerifyCode = res.responseDescription
        this.$toast(`验证码已发送至您的手机${this.username}`);
        this.sendDisabled = true;
        window.setTimeLoginObj = window.setInterval(() => {
          if ((this.time--) <= 0) {
            this.time = 60;
            this.sendDisabled = false;
            clearInterval(window.setTimeLoginObj);
          }
        }, 1000);
      } else {
        this.$toast(res.responseDescription || '请求失败');
      }
    }
  }
};
</script>

<style lang="less" scoped>
.login_page {
  .content {
    width: 90%;
    margin: 10px auto;
    //margin-top: 54px;
    .smsVerifyCode {
      width: 7.5em;
      display: block;
    }
  }
  .inputMessage{
    border-radius: 10px;
    margin-bottom: 10px;
    height: 50px;
    line-height: 30px;
  }
  .verCodeShow{
    width: 50%;
    height: 100%;
    background-color: white;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-align: center;
  }
  .verCodeInput{
    width: 50%;
    height: 100%;
    background-color: white;
    margin: 0;
    padding: 0;
    display: inline-block;
    

   
  }
}
</style>
